@import "../../styles/variables";

.overlay {
	position: fixed;
	z-index: $z-modal;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background-color: change-color($colour-black, $alpha: 0);
	transition: background-color 0.3s ease;

	&[class*="after-open"] {
		background-color: change-color($colour-black, $alpha: 0.8);
	}

	&[class*="before-close"] {
		background-color: change-color($colour-black, $alpha: 0);
	}

	&.blue {
		background-color: change-color($colour-blue, $alpha: 0);

		&[class*="after-open"] {
			background-color: change-color($colour-blue, $alpha: 0.8);
		}

		&[class*="before-close"] {
			background-color: change-color($colour-blue, $alpha: 0);
		}
	}
}

.modal {
	position: fixed;
	top: size($spacer);
	left: 0;
	width: calc(100vw - #{size($spacer)});
	max-width: size(440);
	max-height: calc(100vh - #{size($spacer * 2)});

	overflow: auto;

	background-color: $colour-white;
	border-radius: 0 size(20) size(20) 0;

	transform: translateX(-100%);
	transition: transform 0.3s ease;

	&[class*="after-open"] {
		transform: translateX(0);
	}

	&[class*="before-close"] {
		transform: translateX(-100%);
	}

	&.right {
		right: 0;
		left: unset;
		padding: size(56) size(40) size(40);

		border-radius: size(20) 0 0 size(20);

		transform: translateX(100%);

		&[class*="after-open"] {
			transform: translateX(0);
		}

		&[class*="before-close"] {
			transform: translateX(100%);
		}
	}
}
