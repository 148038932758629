@import "../../styles/variables";

.label {
	display: block;

	@media #{$md-viewport-down} {
		grid-column: span 2;
	}

	span {
		display: block;
		margin-bottom: size(4);
		font-size: size(14);
	}

	input {
		display: block;
		width: 100%;
		padding: size(12) 0;

		font-size: size(20);
		text-indent: size(12);

		appearance: none;
		background: none;
		border: 1px solid $colour-grey;
		border-radius: size(4);
		outline: none;

		transition: color 0.15s ease, border-color 0.15s ease;

		&:hover,
		&:focus {
			color: $colour-blue;
			border-color: $colour-blue;
		}
	}

	small {
		display: block;
		margin-top: size(4);

		font-size: size(14);
		opacity: 0.4;
	}
}

.wide {
	grid-column: span 2;
}
