@import '../../styles/variables';

.header {
	position: sticky;
	top: size(16);
	z-index: $z-header;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: size(72);
	padding: 0 size(24);
	pointer-events: none;

	max-width: calc(100vw - #{size($spacer*2.75)}) !important;

	&:first-child {
		margin-bottom: 2rem;
	}

	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background: #fff;
		border-radius: size(12);

		@supports (backdrop-filter: blur(10px)) {
			background: rgba(255,255,255,0.9);
			backdrop-filter: blur(16px) saturate(5);
		}
	}

	svg {
		vertical-align: middle;
	}

	a:hover {
		border-bottom: none;
	}

	&:hover li:not(:hover) a:not(.logo) {
		opacity: 0.28;
	}

	@media (max-width: 1290px) {
		max-width: calc(100vw - #{$spacer}) !important;
		pointer-events: all;

		> nav {
			display: none !important;
		}

		> * {
			margin: 0 !important;

			&:not(:last-child) {
				transition: transform 0.15s ease;
				transform: translateY(var(--top-pull, 0));
			}
		}
	}
}

.logo {
	margin-right: size(50);
	pointer-events: all;

	svg:last-child {
		display: none;
		width: auto;
		height: size(26);

		path {
			fill: #000;
			stroke: none;
		}
	}

	@media (max-width: 1832px) {
		margin-right: size($spacer*0.5);
		svg:first-child { display: none }
		svg:last-child { display: unset }
	}
}

.primary {
	flex-grow: 1;
}

.nav {
	display: flex;
	list-style: none;
	pointer-events: none;

	li {
		position: relative;
	}

	> li > a {
		display: inline-block;
		padding: size(20);

		color: $colour-black;
		font-size: size(18);
		font-weight: $weight-medium;

		pointer-events: all;
		transition: opacity 0.15s ease;

		@media (max-width: 1580px) {
			padding: size(20) size(10);
			font-size: size(16);
		}
	}

	li:hover a {
		text-decoration: none;
	}

	> li:hover ul {
		opacity: 1;
		pointer-events: all;
	}
}

.subNav {
	position: absolute;
	z-index: $z-header + 1;
	top: 100%;
	left: size(-5);

	display: flex;
	padding: 0 size(25) size(25);
	list-style: none;

	white-space: nowrap;

	background-color: #fff;
	border-radius: 0 0 size(20) size(20);

	opacity: 0;
	transition: opacity 0.15s ease;
	pointer-events: none;

	h5 {
		opacity: 0.5;
		margin-bottom: size(10);
		font-size: size(14);
	}

	li:not(:last-child) {
		padding-right: size(40);
	}

	ul {
		list-style: none;
	}

	a {
		display: block;
		padding: size(5) 0;
		color: $colour-black;
		font-size: size(20);
	}
}

.blue {
	a {
		color: $colour-blue !important;
	}
}

.controls {
	display: flex;
	margin-left: size(44);
	margin-right: size(-12);

	a,
	button {
		display: inline-block;
		padding: size(20) size(12);

		appearance: none;
		background: none;
		border: none;
		cursor: pointer;
		pointer-events: all;
		transition: opacity 0.15s ease;

		&:hover {
			opacity: 0.5;
		}
	}
}

.toggle {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: size(28);
	height: size(28);

	appearance: none;
	background: none;
	border: none;
	border-radius: 0;

	@media (min-width: 1290px) {
		display: none;
	}

	&::before,
	&::after,
	span {
		position: relative;
		display: block;
		width: size(18);
		height: size(3);

		background: $colour-black;
		border-radius: 10px;

		transition: background-color 0.15s ease, opacity 0.15s ease, transform 0.15s ease;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		right: 0;

		width: size(28);
	}

	&::before {
		transform: translateY(#{size(-(14 - 3))});
	}

	&::after {
		transform: translateY(#{size(14 - 3)});
	}

	&.open {
		span {
			opacity: 0;
		}

		&::before,
		&::after {
			background-color: $colour-blue;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}
}
