@import "../../styles/variables";

.wrap {
	width: 100%;
	max-width: $container-width;
	margin: auto;

	@media (max-width: #{$container-width + $spacer * 2}) {
		max-width: calc(100vw - #{$spacer * 2});
	}

	@media #{$md-viewport-down} {
		max-width: calc(100vw - #{$spacer});
	}
}

.wide {
	max-width: calc(100vw - #{size($spacer * 2)});

	@media #{$md-viewport-down} {
		max-width: calc(100vw - #{size($spacer)});
	}

	@media (min-width: calc(2000px)) {
		max-width: calc(2000px - #{$spacer * 2});
	}
}

.grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: size($spacer);

	@media #{$sm-viewport-down} {
		grid-column-gap: 0;
	}
}
