@import "../../styles/variables";

.btn {
	position: relative;

	display: inline-block;
	padding: size(12) size(10) size(11);

	color: $colour-white;
	font-size: size(18);
	font-weight: $weight-medium;
	text-align: center;
	text-decoration: none;

	appearance: none;
	background-color: $colour-black;
	border: none;
	border-radius: size(10);
	cursor: pointer;
	transition: opacity 0.15s ease;

	&:hover {
		text-decoration: none;
		border: none;
		opacity: 0.5;
	}

	&:disabled {
		background-color: #B7B7B7;
		pointer-events: none;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;

		opacity: 0;
		pointer-events: none;
		transition: opacity 0.15s ease;
	}

	&::before {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-color: rgba(255,255,255,0.8);
		border-radius: size(10);
	}

	&::after {
		top: 50%;
		left: 50%;

		display: block;
		width: size(15);
		height: size(15);

		border: 3px solid $colour-black;
		border-top-color: transparent;
		border-radius: 50%;

		@keyframes spinCenter {
			from { transform: translate3d(-50%, -50%, 0) rotate(0) }
			to   { transform: translate3d(-50%, -50%, 0) rotate(360deg) }
		}

		animation: spinCenter infinite linear 1s;
	}
}

.wide {
	width: 100%;
}

.white {
	color: $colour-black;
	background-color: $colour-white;
}

.blue {
	background-color: $colour-blue;
}

.grey {
	background-color: $colour-grey;
}

.text {
	color: $colour-black;
	background-color: transparent;

	&.white {
		color: $colour-white;
	}

	&.blue {
		color: $colour-blue;
	}

	&.grey {
		color: $colour-grey;
	}
}

.outline {
	@extend .text;
	padding: size(10) size(10 - 2) size(9);
	border: 2px solid currentColor;

	&:hover {
		border: 2px solid currentColor;
	}
}

.slim {
	padding: size(8) size(10) size(7);
	font-weight: 100;
}

.small {
	padding: 0;
	font-size: size(14);
}

.rounded {
	border-radius: 100px;
}

.busy {
	pointer-events: none;

	&::before,
	&::after {
		opacity: 1;
	}
}
