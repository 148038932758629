@import "../../styles/variables";

.mobileNav {
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;

	width: 100vw;
	height: 100vh;

	background-color: $colour-grey-light;
	opacity: 0;
	pointer-events: none;

	transition: opacity 0.15s ease;

	@media (min-width: 1290px) {
		display: none;
	}

	a {
		opacity: 1 !important;
	}

	&.open {
		opacity: 1;
		pointer-events: all;
	}

	> button {
		position: absolute;
		top: size(100);
		left: 0;
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 size($spacer);

		color: $colour-grey;
		font-size: size(26);
		font-weight: 600;

		appearance: none;
		background: none;
		border: none;
		border-radius: 0;
	}

	ul {
		position: absolute;
		top: size(150);
		left: 0;
		right: 0;
		bottom: 0;

		overflow: auto;
	}

	li {
		display: flex;
		align-items: center;
		justify-content: space-between;

		color: $colour-black;

		a,
		button:first-child {
			display: block;
			padding: size(10) 0 size(10) size($spacer);

			color: currentColor;
			font-size: size(26);
			font-weight: 600;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			appearance: none;
			background: none;
			border: none;
			border-radius: 0;
		}

		&.additional {
			color: $colour-blue;
		}

		button:last-child {
			padding: size(4) size($spacer);

			color: $colour-grey;
			font-size: size(34);
			font-weight: 600;

			appearance: none;
			background: none;
			border: none;
			border-radius: 0;
		}
	}
}
