@import "../../styles/variables";

.cart {
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - #{size($spacer * 2)});
	overflow-x: hidden;
}

.items {
	margin: size(30) size(-$spacer) 0;
	list-style: none;
	border-top: 1px solid var(--border-color, $colour-grey);
}

.total {
	width: calc(100% + #{size($spacer * 2)});
	margin: 0 size(-$spacer);
	border-top: 1px solid var(--border-color, $colour-grey);
	border-bottom: 1px solid var(--border-color, $colour-grey);
	overflow: hidden;

	&:last-child {
		border-bottom: none;
	}

	th,
	td {
		vertical-align: middle;
	}

	th {
		padding: size(3.5) size(12) size(3.5) size($spacer);
		width: 100%;
		color: rgba(0,0,0,0.5);
		font-size: size(14);
		font-weight: 100;
		text-align: right;
	}

	td {
		padding: size(3.5) size($spacer) size(3.5) size(12);
		font-size: size(20);
		text-align: right;
	}

	tr:first-child {
		th, td {
			padding-top: size(19);
		}
	}

	tr:last-child {
		th, td {
			padding-bottom: size(19);
		}
	}
}

.footer {
	margin: size($spacer) 0 size(-8);

	button {
		margin-bottom: size(8);
	}
}

.total,
.footer {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 1px;
		left: 1px;

		display: block;
		width: 100%;
		height: calc(100% - 2px);

		background: rgba(255,255,255,0.8);
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.15s ease;
	}

	&.busy {
		&::before {
			opacity: 1;
			pointer-events: all;
		}
	}
}

.footer::before {
	top: size(-5);
	left: size(-5);
	width: calc(100% + #{size(10)});
	height: calc(100% + #{size(10)});
}
