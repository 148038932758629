@import "../../styles/variables";

.overlay {
	position: fixed;
	z-index: $z-modal;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background-color: change-color($colour-black, $alpha: 0);
	transition: background-color 0.3s ease;

	&[class*="after-open"] {
		background-color: change-color($colour-black, $alpha: 0.8);
	}

	&[class*="before-close"] {
		background-color: change-color($colour-black, $alpha: 0);
	}
}

.modal {
	position: fixed;
	top: size(124);
	padding: size($spacer);
	left: 50%;

	width: 100%;
	max-width: size(920);

	pointer-events: none;
	opacity: 0;
	transform: translateX(-50%);
	transition: opacity 0.15s ease;
	
	@media #{$md-viewport-down} {
		top: size(24);
	}
	
	@media #{$sm-viewport-down} {
		padding: size($spacer*0.5);
	}

	&[class*="after-open"] {
		opacity: 1;
	}

	&[class*="before-close"] {
		opacity: 0;
	}
}

.prompt {
	margin-bottom: size(16);

	color: #FFFFFF;
	text-align: center;

	opacity: 0.5;
}

.search {
	display: block;
	pointer-events: all;

	input {
		width: 100%;
		padding: size(40) 0;

		font-size: size(48);
		font-weight: bold;
		text-indent: size(48);

		appearance: none;
		background: #fff;
		border: none;
		border-radius: size(20);
		outline: none;
		
		@media #{$sm-viewport-down} {
			padding: size(24) 0;

			font-size: size(24);
			text-indent: size(24);
		}

		.hasResults & {
			border-radius: size(20) size(20) 0 0;
			background: #F2F2F2;
		}

		&::placeholder {
			color: change-color($colour-black, $alpha: 0.32);
		}
	}
}

.results {
	padding: size(32);

	list-style: none;

	background: #fff;
	border-radius: 0 0 size(20) size(20);
	pointer-events: all;
	
	@media #{$sm-viewport-down} {
		padding: size(16);
	}
}

.result {
	display: flex;
	align-items: center;
	padding: size(8);

	color: $colour-black;
	text-decoration: none !important;

	border-radius: size(20);

	&:hover {
		background: #F2F2F2;
	}
}

.image {
	width: size(64);
	height: size(64);
	margin-right: size(16);

	background: #e3e3e3;
	border-radius: size(10);
	overflow: hidden;

	div,
	img,
	span, {
		vertical-align: middle;
	}
}

.text {
	font-size: size(20);
	flex-grow: 1;

	small {
		display: block;
		margin-bottom: size(2);

		font-size: size(14);
		opacity: 0.5;
	}
}

.meta {
	font-size: size(20);
}
