@import "../../styles/variables";

.layout {
	margin-top: size($spacer * 0.25);
}

.image {
	grid-column: span 6;

	border-radius: size(20);
	overflow: hidden;

	@media #{$md-viewport-down} {
		grid-column: span 12;
		position: relative;

		padding-top: 50%;

		> * {
			position: absolute !important;
			top: 0;
			left: 0;
		}
	}

	* {
		width: 100% !important;
		height: 100% !important;
		max-width: unset !important;
		object-fit: cover;
		vertical-align: middle;
	}
}

.content {
	display: flex;
	align-items: center;
	justify-content: center;
	grid-column: span 6;
	padding: size($spacer);

	background-color: $colour-grey-lightest;
	border-radius: size(20);

	@media #{$md-viewport-down} {
		grid-column: span 12;
	}

	p {
		color: #7D7D7D;
		font-size: size(20);
	}

	label:not(:last-child) {
		margin-bottom: size($spacer * 0.5);
	}

	button + a {
		display: block;
		padding: size(15);
		text-align: center;
	}
}

.form {
	width: 100%;
	max-width: size(600);
}
