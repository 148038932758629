@use "sass:math";

// Colours
// =========================================================================

$colour-blue: #0001FE;
$colour-grey: #B7B7B7;
$colour-grey-light: #EBEBEB;
$colour-grey-lightest: #F2F2F2;
$colour-black: #000;
$colour-white: #fff;
$colour-red: #FE0000;

// Fonts
// =========================================================================

$font-system: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$font-family: 'FK Grotesk', $font-system;

$weight-normal: 200;
$weight-medium: 500;

// Z Indexes
// =========================================================================

$z-modal: 10;
$z-header: 5;

// Sizes
// =========================================================================

$container-width: 1400px;
$spacer: 40px;

// Breakpoints
// =========================================================================

$xs-min: 34em;
$sm-min: 48em;
$md-min: 62em;
$lg-min: 75em;

$container: '(min-width: #{$container-width + $spacer * 2})';
$container-up: '(min-width: #{$container-width + $spacer * 2})';
$container-down: '(max-width: #{$container-width + $spacer * 2})';

$xs-viewport: '(min-width: #{$xs-min})';
$sm-viewport: '(min-width: #{$sm-min})';
$md-viewport: '(min-width: #{$md-min})';
$lg-viewport: '(min-width: #{$lg-min})';

$xs-viewport-down: '(max-width: #{$xs-min})';
$sm-viewport-down: '(max-width: #{$sm-min})';
$md-viewport-down: '(max-width: #{$md-min})';
$lg-viewport-down: '(max-width: #{$lg-min})';

// Functions
// =========================================================================

/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
@function decimal-round ($number, $digits: 0, $mode: round) {
	$n: 1;

	// $number must be a number
	@if type-of($number) != number {
		@warn '#{ $number } is not a number.';
		@return $number;
	}

	// $digits must be a unitless number
	@if type-of($digits) != number {
		@warn '#{ $digits } is not a number.';
		@return $number;
	} @else if not unitless($digits) {
		@warn '#{ $digits } has a unit.';
		@return $number;
	}

	@for $i from 1 through $digits {
		$n: $n * 10;
	}

	@if $mode == round {
		@return math.div(round($number * $n), $n);
	} @else if $mode == ceil {
		@return math.div(ceil($number * $n), $n);
	} @else if $mode == floor {
		@return math.div(floor($number * $n), $n);
	} @else {
		@warn '#{ $mode } is undefined keyword.';
		@return $number;
	}
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return math.div($number, $number * 0 + 1);
	}

	@return $number;
}

/**
 * Convert the given PX size to REMs
 */
@function size ($targetSize, $baseSize: 16) {
	@return #{decimal-round(math.div(strip-unit($targetSize), $baseSize), 3)}rem;
}

// Mixins
// =========================================================================

/**
 * $maxFontSize - The max font size (also the fallback)
 */
@mixin responsiveSize($minFontSize, $maxFontSize, $minScreenWidth: 400, $maxScreenWidth: 1260) {
	$fontDiff: $maxFontSize - $minFontSize;
	$screenDiff: $maxScreenWidth - $minScreenWidth;
	font-size: #{$maxFontSize}px;
	font-size: calc(#{$minFontSize}px + (#{$fontDiff}) * (100vw - #{$minScreenWidth}px) / (#{$screenDiff}));

	@media (min-width: #{$maxScreenWidth}px) {
		font-size: #{$maxFontSize}px;
	}

	@media (max-width: #{$minScreenWidth}px) {
		font-size: #{$minFontSize}px;
	}
}
