@import "variables";

// Fonts
// =========================================================================

@font-face {
	font-family: 'FK Grotesk';
	src: url('../public/fonts/FKGrotesk-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FK Grotesk';
	src: url('../public/fonts/FKGrotesk-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

// Reset
// =========================================================================

* {
	margin: 0;
	padding: 0;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

th, td {
	text-align: left;
	vertical-align: top;
}

img, iframe {
	border: 0;
}

// Global
// =========================================================================

* {
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	box-sizing: border-box;
	font-family: $font-family;
}

html {
	height: 100%;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scroll-behavior: smooth;

	@include responsiveSize(14, 16);
	font-family: $font-family;
	font-weight: normal;
	-webkit-text-size-adjust: 100%;
}

body {
	&[class*="ReactModal__Body--open"] {
		overflow: hidden;
	}
}

a {
	color: $colour-blue;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

h1 {
	margin-bottom: size(16);
	font-size: size(48);
	font-weight: $weight-medium;
	line-height: 1.2em;
}

h2 {
	font-size: size(40);
	font-weight: $weight-medium;
}

h4 {
	margin-bottom: size(14);
	font-size: size(20);
}

p {
	line-height: 1.5em;

	&:not(:last-child) {
		margin-bottom: size(22);
	}
}
