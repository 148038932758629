@import "../../styles/variables";

.discount {
	margin: size(16) 0;
	flex-grow: 1;

	> label {
		display: flex;

		input {
			font-size: size(14);
		}

		> span {
			flex-grow: 1;
			margin-right: size(12);
		}
	}
}

.error {
	margin-bottom: size(7);

	color: darkred;
	font-size: size(14);
}

.active {
	display: flex;
	align-items: center;
	justify-content: space-between;

	small {
		display: inline-block;
		margin-right: size(10);
		opacity: 0.5;
	}
}
