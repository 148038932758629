@import "../../styles/variables";

.footer {
	margin-top: size(160);
	background-color: $colour-grey-lightest;

	h5 {
		font-size: size(16);
		font-weight: 500;
	}
}

.section {
	position: relative;
	padding: size(75) 0;
	overflow: hidden;

	&:first-child {
		border-bottom: 1px solid rgba(0,0,0,0.09);
	}

	&:last-child::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;

		display: block;
		width: calc(100vw + 100vh);
		height: 1px;

		background-color: rgba(0,0,0,0.09);

		transform: rotate(15deg);
		transform-origin: 0 0;
	}

	@media #{$md-viewport-down} {
		padding: size(50) 0;
	}
}

.left {
	grid-column: span 5;

	@media #{$md-viewport-down} {
		grid-column: span 12;
	}
}

.right {
	grid-column: span 7;

	@media #{$md-viewport-down} {
		grid-column: span 12;
	}
}

.logo {
	display: block;
	margin-bottom: size(50);
}

.cards {
	display: flex;
	align-items: center;
	margin-top: size(10);

	svg:not(:last-child) {
		margin-right: size(10);
	}
}

.newsletter {
	em {
		font-size: size(24);
		font-style: normal;
	}

	small {
		font-size: size(16);
		opacity: 0.5;
	}

	form {
		display: flex;
		align-items: center;

		background-color: $colour-white;
		border: 1px solid $colour-grey;

		@media #{$sm-viewport-down} {
			display: block;
		}
	}

	label {
		display: block;
		width: 100%;
		flex-shrink: 9999;

		&:not(:last-of-type) {
			border-right: 1px solid $colour-grey;
		}

		@media #{$sm-viewport-down} {
			border-right: none !important;
			border-bottom: 1px solid $colour-grey;
		}

		span {
			display: block;
			padding: size(12) size(16) 0 size(16);
			font-size: size(14);
		}

		input {
			width: 100%;
			padding: size(5) 0 size(10) 0;

			font-size: size(20);
			text-indent: size(16);

			appearance: none;
			background: none;
			border: none;
			border-radius: 0;
		}
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: size(48);
		height: size(48);
		margin: size(8);

		color: $colour-white;
		font-size: size(40);
		font-weight: 100;

		appearance: none;
		background: $colour-blue;
		border: none;
		border-radius: 50%;
		cursor: pointer;

		@media #{$sm-viewport-down} {
			width: calc(100% - #{size(8 * 2)});
			border-radius: size(20);

			&::before {
				content: 'Join';
				display: inline-block;
				margin-right: size(10);
				font-size: size(24);
			}
		}
	}
}

.about {
	h5 {
		margin-bottom: size(16);
	}

	p {
		max-width: size(440);
		font-size: size(14) !important;
	}
}

.nav {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: size($spacer * 0.5);

	@media #{$sm-viewport-down} {
		grid-template-columns: 1fr 1fr;
		grid-row-gap: size($spacer);
	}

	ul {
		list-style: none;
	}

	h5 {
		margin-bottom: size(12);
	}

	a {
		display: block;
		padding: size(4) 0;
		color: $colour-black;
	}

	em {
		color: $colour-blue;
		font-style: normal;
	}
}
