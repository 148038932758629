@import "../../styles/variables";

.announcement {
	position: relative;

	margin-top: size(16);
	margin-bottom: size(8);
	padding: size(6) size(40) size(4.5);

	color: $colour-white;
	font-weight: $weight-medium;
	text-align: center;

	background-color: $colour-blue;
	border-radius: size(20);

	@media #{$md-viewport-down} {
		padding-left: size(10);
	}

	a {
		color: inherit;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.dismiss {
	position: absolute;
	top: 0;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0 size(14);

	appearance: none;
	background: none;
	border: none;
	border-radius: 0 size(20) size(20) 0;
	cursor: pointer;

	svg {
		vertical-align: middle;
	}

	g {
		transition: opacity 0.15s ease;
	}

	&:hover g {
		opacity: 1;
	}
}

.marquee {
	position: relative;

	display: flex;
	flex-direction: row;
	width: 100%;

	overflow-x: hidden;

	@mixin gradient {
		background: linear-gradient(to right, $colour-blue, change-color($colour-blue, $alpha: 0));
	}

	&::before,
	&::after {
		@include gradient;
		content: "";
		height: 100%;
		position: absolute;
		width: size(20);
		z-index: 2;
	}

	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}

	span {
		flex: 0 0 auto;
		min-width: 100%;
		z-index: 1;
		display: flex;
		flex-direction: row;
		align-items: center;

		&:last-child {
			display: none;
		}

		@media #{$md-viewport} {
			display: block;
		}

		@media #{$md-viewport-down} {
			animation: scroll 20s linear infinite;
			animation-play-state: running;
			padding: 0 size(20);

			&:last-child {
				display: unset;
			}
		}

		@keyframes scroll {
			0% {
				transform: translateX(0%);
			}
			100% {
				transform: translateX(-100%);
			}
		}
	}
}
