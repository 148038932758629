@import "../../styles/variables";

.lineItem {
	display: flex;
	align-items: center;
	padding: size(16.5) size(40);

	border-bottom: 1px solid var(--border-color, $colour-grey);

	div,
	img {
		vertical-align: middle;
	}

	> div:first-child {
		border-radius: size(10);
		overflow: hidden;
	}
}

.text {
	width: 100%;
	flex-shrink: 9999;
	margin: 0 size(16);

	small {
		display: block;
		color: rgba(0,0,0,0.5);
		font-size: size(14);
	}

	strong {
		display: block;
		font-weight: 100;
		font-size: size(20);
	}

	span {
		display: block;
		font-size: size(14);
	}
}

.qty {
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		display: block;
		margin: 0 size(7);
		font-size: size(20);
	}

	button {
		position: relative;

		display: block;
		width: size(20);
		height: size(20);

		appearance: none;
		background: none;
		border: 2px solid rgba(0,0,0,0.5);
		border-radius: 50%;
		cursor: pointer;
	}
}

.increase {
	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;

		display: block;
		width: size(9);
		height: size(2);

		background: rgba(0,0,0,0.5);
		border-radius: 10px;
	}

	&::before {
		transform: translate3d(-50%, -50%, 0);
	}

	&::after {
		transform: translate3d(-50%, -50%, 0) rotate(90deg);
	}
}

.decrease {
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;

		display: block;
		width: size(9);
		height: size(2);

		background: rgba(0,0,0,0.5);
		border-radius: 10px;
		transform: translate3d(-50%, -50%, 0);
	}
}
