@import "../../styles/variables";

.prose {
	h2 {
		font-size: size(48);
	}

	h3 {
		font-size: size(32);
		line-height: 1.3em;

		&:not(:first-child) {
			margin-top: size($spacer);
		}

		&:not(:last-child) {
			margin-bottom: size($spacer*0.5);
		}
	}

	blockquote {
		font-size: size(40);
		font-weight: 100;
		line-height: 1.3em;

		&:not(:last-child) {
			margin-bottom: size($spacer*0.5);
		}
	}

	p,
	li {
		font-size: size(20);
		line-height: 1.6em;

		&:not(:last-child) {
			margin-bottom: size($spacer*0.5);
		}
	}

	ul,
	ol {
		margin-left: size($spacer);
	}
}

.isPage {
	max-width: size(920);
	margin: size($spacer) auto;
}

.small {
	p {
		font-size: size(16);
	}
}
